<template>
  <div>
    <div class="vx-row my-6">
      <div class="vx-col sm:w-1/3">
        <span>CN Supplier ID</span>
      </div>
      <div class="vx-col sm:w-1/3">
        <vs-input class="w-full" v-model="code" placeholder="Auto Generate" readonly />
      </div>
    </div>
    <div class="vx-row my-6">
      <div class="vx-col sm:w-1/3">
        <span>Reference</span>
      </div>
      <div class="vx-col sm:w-1/3">
        <vs-input
          class="w-full"
          placeholder="Reference"
          v-model="inputReference.value"
          :readonly="inputReference.readonly"
        />
      </div>
    </div>
    <div class="vx-row my-6">
      <div class="vx-col sm:w-1/3">Supplier ID</div>
      <div class="vx-col sm:w-1/3">
        <multiselect
          class="selectExample w-full"
          v-model="selectSupplier.value"
          :options="selectSupplier.option"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          select-label=""
          deselect-label=""
          :searchable="selectSupplier.searchabel"
          :internal-search="selectSupplier.internal_search"
          :loading="selectSupplier.loading"
          :max-height="selectSupplier.max_height"
          :limit="selectSupplier.limit"
          :disabled="selectSupplier.disabled"
          v-validate="selectSupplier.validate"
          :name="selectSupplier.name"
          @input="onSelectedSupplier"
          placeholder="Type to search"
          @search-change="handlerSearchSupplier"
          :custom-label="selectSupplier.label"
        />
      </div>
    </div>
    <div class="vx-row my-6">
      <div class="vx-col sm:w-1/3">
        <span>Supplier Name</span>
      </div>
      <div class="vx-col sm:w-1/3">
        <vs-input
          class="w-full"
          placeholder="Supplier Name"
          :value="selectSupplier.value ? selectSupplier.value.name : ''"
          readonly
        />
      </div>
    </div>
    <div class="vx-row my-6">
      <div class="vx-col sm:w-1/3">
        <span>Document Date</span>
      </div>
      <div class="vx-col sm:w-1/3">
        <datepicker
          :name="dateDocument.name"
          :format="dateDocument.format"
          :inline="false"
          v-model="dateDocument.value"
          placeholder="Select Date"
          v-validate="dateDocument.validate"
          :disabledDates="dateDocument.disabled_date"
          :disabled="dateDocument.disabled"
        ></datepicker>
      </div>
    </div>
    <div class="vx-row my-6">
      <div class="vx-col sm:w-1/3">
        <span>Posting Date</span>
      </div>
      <div class="vx-col sm:w-1/3">
        <datepicker
          :name="datePosting.name"
          :format="datePosting.format"
          :inline="false"
          v-model="datePosting.value"
          placeholder="Select Date"
          v-validate="datePosting.validate"
          :disabledDates="datePosting.disabled_date"
          :disabled="datePosting.disabled"
        ></datepicker>
      </div>
    </div>
    <!-- <div class="vx-row my-6">
      <div class="vx-col sm:w-1/3">
        <span>Settle by Customer Deposit</span>
      </div>
      <div class="vx-col sm:w-1/3">
        <vs-checkbox
          v-model="checkSettle.value"
          vs-value="false"
          :disabled="checkSettle.disabled"
        />
      </div>
    </div> -->
    <div class="vx-row my-6">
      <div class="vx-col sm:w-1/3">
        <span>Note</span>
      </div>
      <div class="vx-col sm:w-1/3">
        <vs-textarea v-model="taNote.value" :disabled="taNote.disabled" />
      </div>
    </div>
    <div class="vx-row my-6">
      <div class="vx-col sm:w-1/3">
        <span
          >Attachment <br />
          <!-- <small style="color: red"
            >(only: jpg, jpeg, png, pdf, doc, docx, xls, xlsx)</small
          > -->
        </span>
      </div>
      <div class="vx-col sm:w-1/3">
        <vs-input
          type="file"
          :accept="fileAttactment.accepted_types"
          :multiple="fileAttactment.multiple"
          @change="handleAttactmentChange($event)"
          class="w-full"
          v-model="fileAttactment.value"
          :disabled="fileAttactment.disabled"
        />
        <div v-if="fileAttactment.files.length > 0">
          <vs-divider> List Attachments </vs-divider>
          <template v-for="(file, i) in fileAttactment.files">
            <div class="vx-row my-2" :key="i" v-if="file.flag != 'remove'">
              <div class="vx-col w-full">
                <div class="flex gap-2">
                  {{ i + 1 }}.
                  <a href="javascript:void(0);" v-on:click="handleShowAttachment(i)">{{
                    file.name
                  }}</a>

                  <vx-tooltip text="remove" style="margin-left: auto" v-if="!ReadOnly">
                    <vs-button
                      color="danger"
                      type="filled"
                      size="small"
                      @click="deleteAttachment(i)"
                      icon-pack="feather"
                      icon="icon-trash"
                    />
                  </vx-tooltip>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <vs-table stripe border :sst="true" :data="dataLineCnSupplier">
      <template slot="thead">
        <vs-th sort-key="" align="center">Rec.</vs-th>
        <vs-th sort-key="" align="center">VAT-In</vs-th>
        <vs-th sort-key="" align="center">
          <!-- <div :style="'width:' + (dataLineCnSupplier.length > 0 ? 140 : null) + 'px'">
            Territory
          </div> -->
          Territory
        </vs-th>
        <vs-th sort-key="" align="center">Coa</vs-th>
        <vs-th sort-key="" align="center">Customer</vs-th>
        <vs-th sort-key="" align="center">Description</vs-th>
        <vs-th sort-key="" align="center">Value Debit</vs-th>
        <vs-th sort-key="" align="center">Value Credit</vs-th>
        <vs-th sort-key="" align="center">Cost Center</vs-th>
        <vs-th sort-key="" align="center"></vs-th>
      </template>
      <template slot-scope="{ data }">
        <template v-for="(tr, i) in data">
          <vs-tr :data="tr" :key="i" v-if="tr.flag != 'remove'">
            <vs-td sort-key="" align="center"
              ><vs-checkbox
                v-model="dataLineCnSupplier[i].checkRec.value"
                vs-value="true"
                @change="handleRecChange($event, i)"
                :disabled="dataLineCnSupplier[i].checkRec.disabled"
            /></vs-td>
            <vs-td sort-key="" align="center"
              ><vs-checkbox
                v-model="dataLineCnSupplier[i].checkVatIn.value"
                @change="handleVatInChange($event, i)"
                vs-value="true"
                :disabled="dataLineCnSupplier[i].checkVatIn.disabled"
            /></vs-td>
            <vs-td sort-key="" align="center"
              ><multiselect
                class="selectExample w-full"
                v-model="dataLineCnSupplier[i].selectTerritory.value"
                :options="dataLineCnSupplier[i].selectTerritory.option"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                select-label=""
                deselect-label=""
                :searchable="dataLineCnSupplier[i].selectTerritory.searchabel"
                :internal-search="dataLineCnSupplier[i].selectTerritory.internal_search"
                :loading="dataLineCnSupplier[i].selectTerritory.loading"
                :max-height="dataLineCnSupplier[i].selectTerritory.max_height"
                :limit="dataLineCnSupplier[i].selectTerritory.limit"
                :disabled="dataLineCnSupplier[i].selectTerritory.disabled"
                v-validate="dataLineCnSupplier[i].selectTerritory.validate"
                :name="dataLineCnSupplier[i].selectTerritory.name"
                open-direction="bottom"
                @input="
                  (v) => {
                    onSelectedTerritory(v, i);
                  }
                "
                @open="
                  (e) => {
                    multiselectOpen(e, i);
                  }
                "
                @close="multiselectClose"
                placeholder="Type to search"
                @search-change="
                  (s) => {
                    handlerSearchTerritory(s, i);
                  }
                "
                :custom-label="dataLineCnSupplier[i].selectTerritory.label"
            /></vs-td>
            <vs-td sort-key="" align="center"
              ><multiselect
                class="selectExample w-full"
                v-model="dataLineCnSupplier[i].selectCoa.value"
                :options="dataLineCnSupplier[i].selectCoa.option"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                select-label=""
                deselect-label=""
                :searchable="dataLineCnSupplier[i].selectCoa.searchabel"
                :internal-search="dataLineCnSupplier[i].selectCoa.internal_search"
                :loading="dataLineCnSupplier[i].selectCoa.loading"
                :max-height="dataLineCnSupplier[i].selectCoa.max_height"
                :limit="dataLineCnSupplier[i].selectCoa.limit"
                :disabled="dataLineCnSupplier[i].selectCoa.disabled"
                v-validate="dataLineCnSupplier[i].selectCoa.validate"
                :name="dataLineCnSupplier[i].selectCoa.name"
                @input="onSelectedSupplier"
                @open="
                  (e) => {
                    multiselectOpen(e, i);
                  }
                "
                @close="multiselectClose"
                placeholder="Type to search"
                open-direction="bottom"
                @search-change="
                  (s) => {
                    handlerSearchCoa(s, i);
                  }
                "
                :custom-label="dataLineCnSupplier[i].selectTerritory.label"
            /></vs-td>
            <vs-td sort-key="" align="center"
              ><multiselect
                class="selectExample w-full"
                v-model="dataLineCnSupplier[i].selectCustomer.value"
                :options="dataLineCnSupplier[i].selectCustomer.option"
                :multiple="false"
                :allow-empty="true"
                :group-select="false"
                select-label=""
                deselect-label=""
                :searchable="dataLineCnSupplier[i].selectCustomer.searchabel"
                :internal-search="dataLineCnSupplier[i].selectCustomer.internal_search"
                :loading="dataLineCnSupplier[i].selectCustomer.loading"
                :max-height="dataLineCnSupplier[i].selectCustomer.max_height"
                :limit="dataLineCnSupplier[i].selectCustomer.limit"
                :disabled="dataLineCnSupplier[i].selectCustomer.disabled"
                v-validate="dataLineCnSupplier[i].selectCustomer.validate"
                :name="dataLineCnSupplier[i].selectCustomer.name"
                open-direction="bottom"
                @input="onSelectedSupplier"
                @open="
                  (e) => {
                    multiselectOpen(e, i);
                  }
                "
                @close="multiselectClose"
                placeholder="Type to search"
                @search-change="
                  (s) => {
                    handlerSearchCustomer(s, i);
                  }
                "
                :custom-label="dataLineCnSupplier[i].selectCustomer.label"
            /></vs-td>
            <vs-td sort-key="" align="center">
              <vs-textarea
                v-model="dataLineCnSupplier[i].inputDescription.value"
                :disabled="dataLineCnSupplier[i].inputDescription.disabled"
              />
              <!-- <vs-input
              v-model="dataLineCnSupplier[i].inputDescription.value"
              type="text"
              :disabled="dataLineCnSupplier[i].inputDescription.disabled"
            ></vs-input> -->
            </vs-td>
            <vs-td sort-key="" align="center">
              <!-- v-validate="validate.giroValue" -->
              <vue-number
                :customClass="`w-full`"
                v-model="dataLineCnSupplier[i].inputValueDebit.value"
                @withoutFormat="
                  (v) => {
                    valueDebitChange(i, v);
                  }
                "
                :masked="true"
                :disabled="dataLineCnSupplier[i].inputValueDebit.disabled"
                :name="dataLineCnSupplier[i].inputValueDebit.name"
              />
            </vs-td>
            <vs-td sort-key="" align="center">
              <vue-number
                :customClass="`w-full`"
                v-model="dataLineCnSupplier[i].inputValueCredit.value"
                @withoutFormat="
                  (v) => {
                    valueCreditChange(i, v);
                  }
                "
                :masked="true"
                :disabled="dataLineCnSupplier[i].inputValueCredit.disabled"
                :name="dataLineCnSupplier[i].inputValueCredit.name"
              />
            </vs-td>
            <vs-td sort-key="" align="center"
              ><multiselect
                class="selectExample w-full"
                v-model="dataLineCnSupplier[i].selectCostCenter.value"
                :options="dataLineCnSupplier[i].selectCostCenter.option"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                select-label=""
                deselect-label=""
                :searchable="dataLineCnSupplier[i].selectCostCenter.searchabel"
                :internal-search="dataLineCnSupplier[i].selectCostCenter.internal_search"
                :loading="dataLineCnSupplier[i].selectCostCenter.loading"
                :max-height="dataLineCnSupplier[i].selectCostCenter.max_height"
                :limit="dataLineCnSupplier[i].selectCostCenter.limit"
                :disabled="dataLineCnSupplier[i].selectCostCenter.disabled"
                v-validate="dataLineCnSupplier[i].selectCostCenter.validate"
                :name="dataLineCnSupplier[i].selectCostCenter.name"
                open-direction="bottom"
                @input="onSelectedSupplier"
                @open="
                  (e) => {
                    multiselectOpen(e, i);
                  }
                "
                @close="multiselectClose"
                placeholder="Type to search"
                @search-change="
                  (v) => {
                    handlerSearchCostCenter(v, i);
                  }
                "
                :custom-label="dataLineCnSupplier[i].selectCostCenter.label"
            /></vs-td>
            <vs-td sort-key="" align="center">
              <vx-tooltip text="add" style="margin-left: auto" v-if="i == 1 && !ReadOnly">
                <vs-button
                  color="success"
                  type="filled"
                  size="small"
                  @click="addLine()"
                  icon="add"
                />
              </vx-tooltip>
              <vx-tooltip
                text="remove"
                style="margin-left: auto"
                v-else-if="i != 0 && !ReadOnly"
              >
                <vs-button
                  color="danger"
                  type="filled"
                  size="small"
                  @click="deleteLine(i)"
                  icon-pack="feather"
                  icon="icon-trash"
                />
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
        <vs-tr>
          <vs-td colspan="6" align="right">Total</vs-td>
          <vs-td align="right">{{ formatNumber(valueTotal.Debit) }}</vs-td>
          <vs-td align="right">{{ formatNumber(valueTotal.Credit) }}</vs-td>
        </vs-tr>
        <vs-tr v-for="index in countLimit" :key="index + data.length + '1'">
          <vs-td colspan="100%"></vs-td
        ></vs-tr>
        <vs-tr v-for="index in countLimit" :key="index + data.length + '2'">
          <vs-td colspan="100%"></vs-td
        ></vs-tr>
      </template>
    </vs-table>
    <div v-if="!ReadOnly">
      <vs-button color="primary" type="filled" @click="handleSubmitWithoutApproval"
        >SAVE</vs-button
      >
      <vs-button class="ml-2" color="success" type="filled" @click="handleSubmitApproval"
        >SAVE & APPROVAL</vs-button
      >
    </div>
    <vs-popup title="Preview" :active.sync="fileShow">
      <div class="vx-row m-2 mt-6">
        <div class="vx-col w-full">
          <img
            width="100%"
            :src="fileAttactment.preview ? fileAttactment.preview.src : ''"
            :alt="fileAttactment.preview ? fileAttactment.preview.name : ''"
            srcset=""
          />
        </div>
      </div>
    </vs-popup>

    <vs-prompt
      @cancel="
        (inputTaxInvoice.tax_invoice_number = ''),
          (inputTaxInvoice.tax_invoice_date = null),
          (inputTaxInvoice.dpp = 0),
          (inputTaxInvoice.send_approval = false),
          (inputTaxInvoice.without_format_dpp = 0),
          (inputTaxInvoice.is_valid = true)
      "
      @close="
        (inputTaxInvoice.tax_invoice_number = ''),
          (inputTaxInvoice.tax_invoice_date = null),
          (inputTaxInvoice.dpp = 0),
          (inputTaxInvoice.send_approval = false),
          (inputTaxInvoice.without_format_dpp = 0),
          (inputTaxInvoice.is_valid = true)
      "
      @accept="acceptInputTaxInvoice"
      :active.sync="inputTaxInvoice.show"
      title="Input Tax Invoice"
      :is-valid="inputTaxInvoiceValid || inputTaxInvoice.is_valid"
      accept-text="Save"
    >
      <div class="con-exemple-prompt">
        <div>
          <vs-input
            class="w-full"
            type="text"
            label="Tax Invoice Number"
            v-model="inputTaxInvoice.tax_invoice_number"
          />
        </div>
        <div>
          <label for class="vs-input--label">Tax Invoice Date</label>
          <datepicker
            name="tax_invoice_date"
            :format="inputTaxInvoice.format"
            :inline="false"
            v-model="inputTaxInvoice.tax_invoice_date"
            label="Tax Invoice Date"
            placeholder="Select Date"
          ></datepicker>
        </div>
        <div>
          <VueNumber
            class="w-full"
            type="number"
            label="Dpp"
            @withoutFormat="valueDppChange"
            :masked="true"
            v-model="inputTaxInvoice.dpp"
          />
        </div>
        <vs-alert
          :active="!inputTaxInvoiceValid && !inputTaxInvoice.is_valid"
          color="danger"
          icon="new_releases"
        >
          Fields can not be empty please enter the data
        </vs-alert>
      </div>
    </vs-prompt>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import VueNumber from "@/components/vue-number/component.vue";
import moment from "moment";
export default {
  props: {
    SelectDT: Object,
    ReadOnly: Boolean,
  },
  components: {
    Datepicker,
    VueNumber,
  },
  data: () => ({
    inputTaxInvoice: {
      send_approval: false,
      show: false,
      without_format_dpp: 0,
      is_valid: true,
      tax_invoice_number: "",
      tax_invoice_date: "",
      format: "yyyy-MM-dd",
      dpp: "",
    },
    url_coa: "/api/v1/master/chart-of-account",
    url_territory: "/api/v1/master/multiple-territory",
    url_customer: "/api/v1/master/customer",
    url_cost_center: "/api/v1/master/cost-center",
    url_supplier: "/api/v1/master/suppliers",
    url_edit_cn_supplier: "/api/v2/finance/credit-note-supplier/edit",
    url_create_cn_supplier: "/api/v2/finance/credit-note-supplier/store",

    isOpenDropdown: false,
    defaultLimit: 1,
    countLimit: 1,
    STS_OPEN: 0,
    STS_INQUIRY: 1,
    STS_WAITING_APPROVAL: 2,
    STS_APPROVED: 3,
    STS_CLOSED: 4,
    STS_REJECTED: 5,
    STS_CENCELED: 6,
    inputReference: {
      value: "",
      readonly: false,
    },
    dataTerrytories: [],
    allDataTerrytories: [],
    dataCoas: [],
    resetDataOptionCust: true,
    allDataCoas: [],

    dataCustomers: [],
    allDataCustomers: [],

    dataSuppliers: [],
    allDataSuppliers: [],

    dataCostCenters: [],
    allDataCostCenters: [],
    selectSupplier: {
      name: "supplier",
      loading: false,
      searchabel: true,
      internal_search: false,
      validate: "required",
      max_height: 200,
      disabled: false,
      limit: 5,
      option: [
        {
          id: 0,
          code: "All",
          name: "-",
        },
      ],
      value: null,
      label: ({ code, name }) => {
        return `${code} - ${name}`;
      },
    },
    dateDocument: {
      name: "document_date",
      format: "yyyy-MM-dd",
      value: new Date(),
      validate: "required",
      disabled: false,
      disabled_date: {
        // from: new Date(),
      },
    },
    datePosting: {
      name: "document_date",
      format: "yyyy-MM-dd",
      value: new Date(),
      validate: "required",
      disabled: false,
      disabled_date: {
        // from: new Date(),
      },
    },
    checkSettle: {
      name: "settle",
      value: true,
      // validate: "required",
      disabled: true,
    },
    taNote: {
      disabled: false,
      name: "note",
      value: "",
      height: "110",
    },
    fileAttactment: {
      multiple: true,
      disabled: false,
      value: null,
      preview: null,
      accepted_types: ["jpg", "jpeg", "png", "pdf", "doc", "docx", "xls", "xlsx"],
      files: [],
    },
    dataLineCnSupplier: [],
    fileShow: false,
    code: null,
    valueTotal: {
      Debit: 0,
      Credit: 0,
    },
  }),
  computed: {
    inputTaxInvoiceValid() {
      return (
        this.inputTaxInvoice.tax_invoice_number.length > 0 &&
        moment(this.inputTaxInvoice.tax_invoice_date).format("YYYY-MM-DD") !=
          "Invalid date" &&
        parseFloat(this.inputTaxInvoice.without_format_dpp) > 0
      );
    },
  },
  watch: {},
  mounted() {
    this.$vs.loading();
    Promise.all([
      this.getOptionTerritory(),
      this.getOptionCoa(),
      this.getOptionCustomer(),
      this.getOptionCostCenter(),
      this.getOptionSupplier(),
    ]).then((r) => {
      console.log("promise", r);
      this.$vs.loading.close();
      if (this.SelectDT.credit_note_supplier_line) {
        this.SelectDT.credit_note_supplier_line.forEach((el) => {
          this.addLine(el);
          console.log("");
          if (el.chart_of_account_code != "") {
            this.handleSetSelectCoa(
              el.chart_of_account_code,
              this.dataLineCnSupplier.length - 1
            );
          }

          if (el.territory_code != "") {
            this.handleSetSelectTerritory(
              el.territory_code,
              this.dataLineCnSupplier.length - 1
            );
          }
          if (el.customer_code != "") {
            this.handleSetSelectCustomer(
              el.customer_code,
              this.dataLineCnSupplier.length - 1
            );
          }
          if (el.cost_center_name != "") {
            this.handleSetSelectCostCenter(
              el.cost_center_name,
              this.dataLineCnSupplier.length - 1
            );
          }
          console.log("");
        });
        this.calcTotalDebit();
        this.calcTotalCredit();
      } else {
        this.addLine();
        this.addLine();
      }
      this.selectSupplier.option = this.dataSuppliers;

      this.setDataFromSelect();
    });
  },
  methods: {
    valueDppChange(v) {
      this.inputTaxInvoice.without_format_dpp = v;
    },
    setDataFromSelect() {
      if (!this.SelectDT.ID) {
        return;
      }
      if (this.ReadOnly) {
        this.inputReference.readonly = true;
        this.selectSupplier.disabled = true;
        this.dateDocument.disabled = true;
        this.datePosting.disabled = true;
        this.taNote.disabled = true;
        this.fileAttactment.disabled = true;
      }
      this.code = this.SelectDT.code;
      this.inputReference.value = this.SelectDT.reference;
      console.log("this.SelectDT", this.SelectDT);
      this.handleSetSelectSupplier(this.SelectDT.supplier_code);
      this.dateDocument.value = new Date(this.SelectDT.date);
      this.datePosting.value = new Date(this.SelectDT.posting_date);
      this.checkSettle.value = this.SelectDT.is_settlement;
      this.taNote.value = this.SelectDT.note;
      if (this.SelectDT.credit_note_supplier_attachment) {
        this.SelectDT.credit_note_supplier_attachment.forEach((el) => {
          this.fileAttactment.files.push({
            id: el.ID,
            flag: "",
            name: el.file_name,
            file_path: el.path,
          });
        });
      }
    },
    acceptInputTaxInvoice(approval = false) {
      if (
        this.inputTaxInvoice.tax_invoice_number == "" ||
        this.inputTaxInvoice.tax_invoice_date == "" ||
        this.inputTaxInvoice.dpp == 0
      ) {
        this.inputTaxInvoice.is_valid = false;
        this.inputTaxInvoice.show = true;
      } else {
        if (this.inputTaxInvoice.send_approval) {
          this.handleSubmit(true);
        } else {
          this.handleSubmit();
        }
      }
    },
    multiselectOpen(e, i) {
      this.countLimit = this.dataLineCnSupplier[i].selectTerritory.limit;
      this.isOpenDropdown = !this.isOpenDropdown;
    },
    multiselectClose(v) {
      if (this.isOpenDropdown) {
        this.countLimit = this.defaultLimit;
        this.isOpenDropdown = false;
      } else {
        this.isOpenDropdown = !this.isOpenDropdown;
      }
    },
    formatNumber(s) {
      let sfinal = "0";
      if (s) {
        sfinal = s.toString().replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${","}`);
      }
      return sfinal;
    },
    onSelectedSupplier(d) {
      console.log("<<<<<<<<<>>>>>>>>>>>>", d);
    },
    onSelectedTerritory(v, i) {
      console.log("i=>", i, ", territory_id", v);
      this.dataLineCnSupplier[i].selectCustomer.value = null;
      this.filterCustomerByTerritory(v.id, i);
    },
    handleSetSelectSupplier(code) {
      if (code != "") {
        this.selectSupplier.loading = true;
        this.selectSupplier.search = code;
        let supplier = this.getOptionSupplier(code);
        supplier.then((r) => {
          if (this.selectSupplier.search == code) {
            for (var supp = 0; supp < r.data.records.length; supp++) {
              if (r.data.records[supp].Code == code) {
                this.selectSupplier.value = {
                  id: r.data.records[supp].ID,
                  code: r.data.records[supp].Code,
                  name: r.data.records[supp].Name,
                };
              }
            }
            this.selectSupplier.loading = false;
          }
        });
      }
    },
    handlerSearchSupplier(s) {
      if (s != "") {
        this.selectSupplier.loading = true;
        this.selectSupplier.search = s;
        let supplier = this.getOptionSupplier(s);
        supplier.then((r) => {
          if (this.selectSupplier.search == s) {
            this.selectSupplier.option = [];
            for (var supp = 0; supp < r.data.records.length; supp++) {
              this.selectSupplier.option.push({
                id: r.data.records[supp].ID,
                code: r.data.records[supp].Code,
                name: r.data.records[supp].Name,
              });
            }
            this.selectSupplier.loading = false;
          }
        });
      } else {
        this.selectSupplier.option = this.allDataSuppliers;
      }
    },
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("DD/MM/YYYY hh:mm");
      }
    },
    handleAttactmentChange(event) {
      const files = event.target.files;
      if (this.fileAttactment.files == null) {
        this.fileAttactment.files = [];
      }
      if (files.length > 0) {
        if (this.fileAttactment.multiple) {
          for (let i = 0; i < files.length; i++) {
            this.fileAttactment.files.push(files[i]);
          }
          console.log(this.fileAttactment.files);
        } else {
          console.log(files);
        }
      } else {
        // this.errorMessage = "Please select a file";
      }
    },
    handleShowAttachment(i) {
      if (this.fileAttactment.files[i]) {
        let src = "";
        console.log("this.fileAttactment.files[i]", this.fileAttactment.files[i]);
        if (this.fileAttactment.files[i] && this.fileAttactment.files[i].file_path) {
          this.$vs.loading();
          const file = this.getUrlFileAwsS3(this.fileAttactment.files[i].file_path);
          file
            .then((resp) => {
              src = resp.url;
              if (
                this.fileAttactment.files[i].name.search(".jpg") >= 0 ||
                this.fileAttactment.files[i].name.search(".jpeg") >= 0 ||
                this.fileAttactment.files[i].name.search(".png") >= 0
              ) {
                this.fileShow = true;
                this.fileAttactment.preview = this.fileAttactment.files[i];
                this.fileAttactment.preview.src = src;
              } else {
                window.open(src, "_blank");
              }
              this.$vs.loading.close();
            })
            .catch((e) => {
              console.log("get url aws error=>", e);
              this.$vs.loading.close();
            });
        } else {
          src = URL.createObjectURL(this.fileAttactment.files[i]);
          if (this.fileAttactment.files[i].type.search("image") >= 0) {
            this.fileShow = true;
            this.fileAttactment.preview = this.fileAttactment.files[i];
            this.fileAttactment.preview.src = src;
          } else {
            window.open(src, "_blank");
          }
        }
      }
    },
    deleteAttachment(i) {
      if (this.fileAttactment.files[i].flag == "") {
        this.fileAttactment.files[i].flag = "remove";
      } else {
        this.fileAttactment.files.splice(i, 1);
      }
      console.log("this.fileAttactment.files[i]", this.fileAttactment.files[i]);
    },
    deleteLine(i) {
      if (this.dataLineCnSupplier[i].id > 0) {
        this.dataLineCnSupplier[i].flag = "remove";
        this.calcTotalDebit();
        this.calcTotalCredit();
      } else {
        this.dataLineCnSupplier.splice(i, 1);
      }
    },
    addLine(dt) {
      this.dataLineCnSupplier.push(this.getObjectLine(dt));
      if (!(this.SelectDT.ID > 0)) {
        if (this.getObjectLine(dt).checkRec.value) {
          this.handleRecChange(null, this.dataLineCnSupplier.length - 1);
        }
        if (this.getObjectLine(dt).checkVatIn.value) {
          this.handleVatInChange(null, this.dataLineCnSupplier.length - 1);
        }
      }
    },
    getObjectLine(dt) {
      let checkRec = false,
        checkVatIn = false,
        selectTerritory,
        selectCustomer,
        selectCoa,
        selectCc,
        valueCredit = 0,
        valueDebit = 0,
        inputDes = "";
      let flag = "",
        id = 0;
      if (dt) {
        if (dt.ID > 0) {
          flag = "old";
        } else {
          flag = "new";
        }
        id = dt.ID;
        if (dt.receivable == true) {
          checkRec = true;
        }
        if (dt.vat_in == true) {
          checkVatIn = true;
        }
        if (dt.territory_id) {
          selectTerritory = this.dataTerrytories.filter((el) => {
            return el.id == dt.territory_id;
          });
          if (selectTerritory.length > 0) {
            selectTerritory = selectTerritory[0];
          }
        }
        if (dt.chart_of_account_id) {
          selectCoa = this.dataCoas.filter((el) => {
            return el.id == dt.chart_of_account_id;
          });
          if (selectCoa.length > 0) {
            selectCoa = selectCoa[0];
          }
        }
        if (dt.customer_id) {
          selectCustomer = this.dataCustomers.filter((el) => {
            return el.id == dt.customer_id;
          });
          if (selectCustomer.length > 0) {
            selectCustomer = selectCustomer[0];
          }
        }
        if (dt.cost_center_id) {
          selectCc = this.dataCostCenters.filter((el) => {
            return el.id == dt.cost_center_id;
          });
          if (selectCc.length > 0) {
            selectCc = selectCc[0];
          }
        }
        if (dt.value_credit) {
          valueCredit = dt.value_credit;
        }
        if (dt.value_debit) {
          valueDebit = dt.value_debit;
        }
        if (dt.description) {
          inputDes = dt.description;
        }
      } else {
        flag = "new";
      }
      return {
        id: id,
        flag: flag,
        checkRec: {
          name: "rec",
          value: checkRec,
          validate: "required",
          disabled: this.ReadOnly ? true : false,
        },
        checkVatIn: {
          name: "vat_in",
          value: checkVatIn,
          validate: "required",
          disabled: this.ReadOnly ? true : false,
        },
        selectTerritory: {
          name: "territory",
          loading: false,
          searchabel: true,
          internal_search: false,
          validate: "required",
          max_height: 200,
          disabled: this.ReadOnly ? true : false,
          limit: 5,
          option: this.dataTerrytories,
          value: selectTerritory,
          label: ({ code, name }) => {
            return `${code} - ${name}`;
          },
        },
        selectCoa: {
          name: "coa",
          loading: false,
          searchabel: true,
          internal_search: false,
          validate: "required",
          max_height: 200,
          disabled: this.ReadOnly ? true : false,
          limit: 5,
          option: this.dataCoas,
          value: selectCoa,
          label: ({ code, name }) => {
            return `${code} - ${name}`;
          },
        },
        selectCustomer: {
          name: "customer",
          loading: false,
          searchabel: true,
          internal_search: false,
          validate: "required",
          max_height: 200,
          disabled: this.ReadOnly ? true : checkRec || checkVatIn ? true : false,
          limit: 5,
          option: this.dataCustomers,
          value: selectCustomer,
          label: ({ code, name }) => {
            return `${code} - ${name}`;
          },
        },
        inputDescription: {
          name: "description",
          value: inputDes,
          disabled: this.ReadOnly ? true : false,
        },
        inputValueDebit: {
          name: "value_debit",
          value: this.formatNumber(valueDebit),
          withoutFormatValue: valueDebit,
          disabled: this.ReadOnly ? true : checkRec ? true : false,
        },
        inputValueCredit: {
          name: "value_credit",
          value: this.formatNumber(valueCredit),
          withoutFormatValue: valueCredit,
          disabled: this.ReadOnly ? true : checkVatIn ? true : false,
        },
        selectCostCenter: {
          name: "costcenter",
          loading: false,
          searchabel: true,
          internal_search: false,
          validate: "required",
          max_height: 200,
          disabled: this.ReadOnly ? true : checkRec || checkVatIn ? true : false,
          limit: 5,
          option: this.dataCostCenters,
          value: selectCc,
          label: ({ code, name }) => {
            return `${code} - ${name}`;
          },
        },
      };
    },
    handleRecChange(e, i) {
      if (this.dataLineCnSupplier[i]) {
        let v = this.dataLineCnSupplier[i].checkRec.value;
        let v2 = this.dataLineCnSupplier[i].checkVatIn.value;
        if (v) {
          for (let temp_i = 0; temp_i < this.dataLineCnSupplier.length; temp_i++) {
            if (i != temp_i) {
              this.dataLineCnSupplier[temp_i].checkRec.value = false;
              if (!v2&&!this.dataLineCnSupplier[temp_i].checkVatIn.value) {
                this.dataLineCnSupplier[temp_i].inputValueDebit.disabled = false;
                this.dataLineCnSupplier[temp_i].inputValueCredit.disabled = false;
                this.dataLineCnSupplier[temp_i].selectCustomer.disabled = false;
                this.dataLineCnSupplier[temp_i].selectCostCenter.disabled = false;
              }
            }
          }
        }
        if (v) {
          this.dataLineCnSupplier[i].inputValueDebit.disabled = true;
          this.dataLineCnSupplier[i].inputValueCredit.disabled = false;
          this.dataLineCnSupplier[i].inputValueDebit.value = 0;
          this.dataLineCnSupplier[i].inputValueDebit.withoutFormatValue = 0;
        }
        if (this.dataLineCnSupplier[i].checkVatIn.value) {
          this.dataLineCnSupplier[i].checkVatIn.value = !v;
        }
        if (!v && !v2) {
          this.dataLineCnSupplier[i].inputValueDebit.disabled = false;
          this.dataLineCnSupplier[i].inputValueCredit.disabled = false;
          this.dataLineCnSupplier[i].selectCustomer.disabled = false;
          this.dataLineCnSupplier[i].selectCostCenter.disabled = false;
        } else {
          this.dataLineCnSupplier[i].selectCustomer.disabled = true;
          this.dataLineCnSupplier[i].selectCostCenter.disabled = true;
          this.dataLineCnSupplier[i].selectCustomer.value = null;
          this.dataLineCnSupplier[i].selectCostCenter.value = null;
        }
        this.calcTotalDebit();
        this.calcTotalCredit();
      }
      console.log("event", e, "<<>>", this.dataLineCnSupplier[i].checkRec.value);
    },
    handleVatInChange(e, i) {
      if (this.dataLineCnSupplier[i]) {
        let v = this.dataLineCnSupplier[i].checkVatIn.value;
        let v2 = this.dataLineCnSupplier[i].checkRec.value;
        if (v) {
          for (let temp_i = 0; temp_i < this.dataLineCnSupplier.length; temp_i++) {
            if (i != temp_i) {
              this.dataLineCnSupplier[temp_i].checkVatIn.value = false;
              if (!v2) {
                this.dataLineCnSupplier[temp_i].inputValueDebit.disabled = false;
                this.dataLineCnSupplier[temp_i].inputValueCredit.disabled = false;
                this.dataLineCnSupplier[temp_i].selectCustomer.disabled = false;
                this.dataLineCnSupplier[temp_i].selectCostCenter.disabled = false;
              }
            }
          }
        }
        if (v) {
          this.dataLineCnSupplier[i].inputValueCredit.disabled = true;
          this.dataLineCnSupplier[i].inputValueDebit.disabled = false;
          this.dataLineCnSupplier[i].inputValueCredit.value = 0;
          this.dataLineCnSupplier[i].inputValueCredit.withoutFormatValue = 0;
        }
        if (this.dataLineCnSupplier[i].checkRec.value) {
          this.dataLineCnSupplier[i].checkRec.value = !this.dataLineCnSupplier[i]
            .checkVatIn.value;
        }
        if (!v && !v2) {
          this.dataLineCnSupplier[i].inputValueCredit.disabled = false;
          this.dataLineCnSupplier[i].inputValueDebit.disabled = false;
          this.dataLineCnSupplier[i].selectCustomer.disabled = false;
          this.dataLineCnSupplier[i].selectCostCenter.disabled = false;
        } else {
          this.dataLineCnSupplier[i].selectCustomer.disabled = true;
          this.dataLineCnSupplier[i].selectCostCenter.disabled = true;
          this.dataLineCnSupplier[i].selectCustomer.value = null;
          this.dataLineCnSupplier[i].selectCostCenter.value = null;
        }
        this.calcTotalDebit();
        this.calcTotalCredit();
      }
      console.log("event", e, "<<>>", this.dataLineCnSupplier[i].checkVatIn.value);
    },
    valueDebitChange(i, v) {
      this.dataLineCnSupplier[i].inputValueCredit.value = 0;
      this.dataLineCnSupplier[i].inputValueCredit.withoutFormatValue = 0;
      this.dataLineCnSupplier[i].inputValueDebit.withoutFormatValue = v;
      this.calcTotalDebit();
      this.calcTotalCredit();
    },
    valueCreditChange(i, v) {
      this.dataLineCnSupplier[i].inputValueDebit.value = 0;
      this.dataLineCnSupplier[i].inputValueDebit.withoutFormatValue = 0;
      this.dataLineCnSupplier[i].inputValueCredit.withoutFormatValue = v;
      this.calcTotalCredit();
      this.calcTotalDebit();
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    calcTotalDebit() {
      let total = 0;
      this.dataLineCnSupplier.forEach((el, i) => {
        if (el.flag != "remove") {
          total += parseFloat(el.inputValueDebit.withoutFormatValue);
        }
      });
      this.valueTotal.Debit = total;
    },
    calcTotalCredit() {
      let total = 0;
      this.dataLineCnSupplier.forEach((el, i) => {
        if (el.flag != "remove") {
          total += parseFloat(el.inputValueCredit.withoutFormatValue);
        }
      });
      this.valueTotal.Credit = total;
    },
    cekDataValid() {
      if (this.inputReference.value == "") {
        this.$vs.notify({
          color: "danger",
          title: "Warning",
          text: "Reference required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return false;
      }

      if (!this.selectSupplier.value) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select a supplier first",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      } else {
        if (this.selectSupplier.value.id == 0) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Please select a supplier first",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return false;
        }
      }
      if (this.valueTotal.Credit != this.valueTotal.Debit) {
        this.$vs.notify({
          color: "danger",
          title: "Warning",
          text: "Total Debits and Credits must be the same",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        return false;
      }
      let is_checked_rec = false;
      let line_valid = true;
      console.log("validation dataLineCnSupplier=>", this.dataLineCnSupplier);
      this.dataLineCnSupplier.every((dt, i) => {
        if (!is_checked_rec) {
          is_checked_rec = dt.checkRec.value;
        }
        if (!dt.selectTerritory.value) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Please select a Territory first",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          line_valid = false;
          return false;
        } else {
          if (dt.selectTerritory.value.id == 0) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Please select a Territory first",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            line_valid = false;
            return false;
          }
        }
        if (!dt.selectCoa.value) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Please select a COA first",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          line_valid = false;
          return false;
        } else {
          if (dt.selectCoa.value.id == 0) {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Please select a COA first",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            line_valid = false;
            return false;
          }
        }
        if (dt.inputDescription.value == "" || dt.inputDescription.value == undefined) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Description required",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          line_valid = false;
          return false;
        }
        return true;
      });
      if (
        is_checked_rec == false ||
        is_checked_rec == null ||
        is_checked_rec == undefined
      ) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Receivable required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }
      if (line_valid == false) {
        return false;
      }
      return true;
    },
    handleSubmitWithoutApproval() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to create document",
        accept: () => {
          let is_vat_in = this.isVatIn();
          console.log("is_vat_in", is_vat_in);
          if (is_vat_in) {
            this.inputTaxInvoice.show = true;
          } else {
            this.handleSubmit();
          }
        },
      });
    },
    isVatIn() {
      let is_vat_in = false;
      this.dataLineCnSupplier.every((dt, i) => {
        console.log(dt.vat_in, "=>", dt);
        if (dt.checkVatIn.value) {
          is_vat_in = true;
          return false;
        }
        return true;
      });
      return is_vat_in;
    },
    handleSubmit(sendApproval = false) {
      let dataValid = this.cekDataValid();
      if (!dataValid) {
        return;
      }
      const params = this.setFormParams(sendApproval);

      this.$vs.loading();
      let http;
      if (this.SelectDT.ID) {
        http = this.$http.patch(
          this.url_edit_cn_supplier + "/" + this.SelectDT.ID,
          params
        );
      } else {
        http = this.$http.put(this.url_create_cn_supplier, params);
      }
      http
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            if (sendApproval) {
              this.$emit("ChangeTab", this.STS_WAITING_APPROVAL);
            }
            this.$emit("close", 0, true);
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    setFormParams(sendApproval = false) {
      var formData = new FormData();
      if (this.setParams().files != null) {
        this.setParams().files.forEach((file) => {
          formData.append("files", file);
        });
      }

      formData.append("send_approval", sendApproval);
      formData.append("code", this.setParams().code);
      formData.append("status", this.setParams().status);
      formData.append("reference", this.setParams().reference);
      formData.append("supplier_id", this.setParams().supplier_id);
      formData.append("supplier_name", this.setParams().supplier_name);
      formData.append("supplier_code", this.setParams().supplier_code);
      formData.append("date", this.setParams().date);
      formData.append("posting_date", this.setParams().posting_date);
      formData.append("is_settlement", this.setParams().is_settlement);
      formData.append("note", this.setParams().note);
      formData.append("total_debit", this.setParams().total_debit);
      formData.append("total_credit", this.setParams().total_credit);
      formData.append("line", JSON.stringify(this.setParams().line));
      this.setParams().id_removes.forEach((id) => {
        formData.append("id_removes", id);
      });
      this.setParams().id_file_remove.forEach((id) => {
        formData.append("id_file_removes", id);
      });
      return formData;
    },
    setParams() {
      let is_settle = false;
      if (this.checkSettle.value) {
        is_settle = true;
      }
      let line = [];
      let tempDataLine = this.dataLineCnSupplier.filter((el) => {
        return el.flag != "remove";
      });
      let tempRemoveDataLine = this.dataLineCnSupplier.filter((el) => {
        return el.flag == "remove";
      });
      tempDataLine.forEach((el, i) => {
        let cc_id,
          cc_code,
          cc_name,
          cust_id,
          cust_code,
          cust_name,
          tax_invoice_number = "",
          tax_invoice_date = "",
          dpp = "",
          rec = false,
          vat_in = false;
        if (el.checkRec.value) {
          rec = true;
        }
        if (el.checkVatIn.value) {
          vat_in = true;
          tax_invoice_number = this.inputTaxInvoice.tax_invoice_number;
          if (
            moment(this.inputTaxInvoice.tax_invoice_date).format("YYYY-MM-DD") !=
            "Invalid date"
          ) {
            tax_invoice_date = moment(this.inputTaxInvoice.tax_invoice_date).format(
              "YYYY-MM-DD"
            );
          }
          dpp = this.inputTaxInvoice.without_format_dpp;
        }
        if (el.selectCostCenter.value) {
          cc_id = el.selectCostCenter.value.id;
          cc_code = el.selectCostCenter.value.code;
          cc_name = el.selectCostCenter.value.name;
        }
        if (el.selectCustomer.value) {
          cust_id = el.selectCustomer.value.id;
          cust_code = el.selectCustomer.value.code;
          cust_name = el.selectCustomer.value.name;
        }
        line.push({
          id: el.id,
          flag: el.flag,
          territory_id: el.selectTerritory.value.id,
          territory_code: el.selectTerritory.value.code,
          territory_name: el.selectTerritory.value.name,
          chart_of_account_id: el.selectCoa.value.id,
          chart_of_account_code: el.selectCoa.value.code,
          chart_of_account_name: el.selectCoa.value.name,
          customer_id: cust_id,
          customer_code: cust_code,
          customer_name: cust_name,
          cost_center_id: cc_id,
          cost_center_code: cc_code,
          cost_center_name: cc_name,
          value_debit: parseFloat(el.inputValueDebit.withoutFormatValue),
          value_credit: parseFloat(el.inputValueCredit.withoutFormatValue),
          description: el.inputDescription.value,
          receivable: rec,
          vat_in: vat_in,
          tax_invoice_number: tax_invoice_number,
          tax_invoice_date: tax_invoice_date,
          dpp: parseFloat(dpp),
        });
      });
      let idRemoves = [];
      let idFileRemoves = [];
      tempRemoveDataLine.forEach((el) => {
        idRemoves.push(el.id);
      });
      let tempFile = this.fileAttactment.files.filter((el) => {
        return el.flag != "remove";
      });
      let tempFileRemove = this.fileAttactment.files.filter((el) => {
        return el.flag == "remove";
      });
      tempFileRemove.forEach((el) => {
        idFileRemoves.push(el.id);
      });
      return {
        status: this.SelectDT.ID ? this.SelectDT.status : "",
        reference: this.inputReference.value,
        supplier_id: this.selectSupplier.value.id,
        supplier_name: this.selectSupplier.value.name,
        supplier_code: this.selectSupplier.value.code,
        date: moment(this.dateDocument.value).format("YYYY-MM-DD"),
        posting_date: moment(this.datePosting.value).format("YYYY-MM-DD"),
        is_settlement: is_settle,
        note: this.taNote.value,
        total_debit: this.valueTotal.Debit,
        total_credit: this.valueTotal.Credit,
        line: line,
        id_removes: idRemoves,
        id_file_remove: idFileRemoves,
        files: tempFile,
      };
    },
    handleSubmitApproval() {
      // this.cekDataValid();
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to create & send to approval",
        accept: () => {
          let is_vat_in = this.isVatIn();
          if (is_vat_in) {
            this.inputTaxInvoice.show = true;
            this.inputTaxInvoice.send_approval = true;
          } else {
            this.handleSubmit(true);
          }
        },
      });
    },
    getOptionTerritory(s = "") {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.url_territory, {
            params: {
              search: s,
              order: "code",
              sort: "asc",
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              this.dataTerrytories = [];
              for (var ter = 0; ter < resp.data.records.length; ter++) {
                this.dataTerrytories.push(resp.data.records[ter]);
              }
              this.allDataTerrytories = this.dataTerrytories;
              resolve(resp);
            } else {
              this.$vs.loading.close();
              reject(resp);
              // this.$router.push("/");
            }
          });
      });
    },
    getOptionCoa(s = "") {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.url_coa, {
            params: {
              search: s,
              length: 10,
              order: "code",
              sort: "asc",
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              if (s == "") {
                this.dataCoas = [];
                for (var coa = 0; coa < resp.data.records.length; coa++) {
                  this.dataCoas.push({
                    id: resp.data.records[coa].coa.ID,
                    code: resp.data.records[coa].coa.Code,
                    name: resp.data.records[coa].coa.Name,
                  });
                }
                this.allDataCoas = this.dataCoas;
              }
              resolve(resp);
            } else {
              this.$vs.loading.close();
              reject(resp);
              // this.$router.push("/");
            }
          });
      });
    },
    getOptionCustomer(s = "", i) {
      return new Promise((resolve, reject) => {
        let params = {
          search: s,
          length: 10,
          page: 1,
          feature: "cn-supplier",
        };
        if (
          this.dataLineCnSupplier[i] &&
          this.dataLineCnSupplier[i].selectTerritory.value &&
          this.dataLineCnSupplier[i].selectTerritory.value.id > 0
        ) {
          params.territory_id = this.dataLineCnSupplier[i].selectTerritory.value.id;
        }
        this.$http
          .get(this.url_customer, {
            params: params,
          })
          .then((resp) => {
            if (resp.code == 200) {
              if (s == "" || this.resetDataOptionCust == true) {
                this.dataCustomers = [];
                for (var cust = 0; cust < resp.data.records.length; cust++) {
                  this.dataCustomers.push(resp.data.records[cust]);
                }
                this.allDataCustomers = this.dataCustomers;
              }
              resolve(resp);
              this.resetDataOptionCust = false;
            } else {
              this.$vs.loading.close();
              reject(resp);
              // this.$router.push("/");
            }
          });
      });
    },
    getOptionCostCenter(s = "") {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.url_cost_center, {
            params: {
              search: s,
              length: 10,
              page: 1,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              if (s == "") {
                this.dataCostCenters = [];
                for (var cc = 0; cc < resp.data.records.length; cc++) {
                  this.dataCostCenters.push({
                    id: resp.data.records[cc].ID,
                    code: resp.data.records[cc].Code,
                    name: resp.data.records[cc].Name,
                  });
                }
                this.allDataCostCenters = this.dataCostCenters;
              }
              resolve(resp);
            } else {
              this.$vs.loading.close();
              reject(resp);
              // this.$router.push("/");
            }
          });
      });
    },
    getOptionSupplier(s = "") {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.url_supplier, {
            params: {
              search: s,
              length: 10,
              page: 1,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              if (s == "") {
                this.dataSuppliers = [];
                for (var supp = 0; supp < resp.data.records.length; supp++) {
                  this.dataSuppliers.push({
                    id: resp.data.records[supp].ID,
                    code: resp.data.records[supp].Code,
                    name: resp.data.records[supp].Name,
                  });
                }
                this.alldataSuppliers = this.dataSuppliers;
              }
              resolve(resp);
            } else {
              this.$vs.loading.close();
              reject(resp);
              // this.$router.push("/");
            }
          });
      });
    },
    handleSetSelectCoa(code, i) {
      console.log(i, "setSelect=>code coa", code);
      if (code != "") {
        this.dataLineCnSupplier[i].selectCoa.loading = true;
        this.dataLineCnSupplier[i].selectCoa.search = code;
        let coa = this.getOptionCoa(code);
        coa.then((r) => {
          if (this.dataLineCnSupplier[i].selectCoa.search == code) {
            for (var coa = 0; coa < r.data.records.length; coa++) {
              if (r.data.records[coa].coa.Code == code) {
                this.dataLineCnSupplier[i].selectCoa.value = {
                  id: r.data.records[coa].coa.ID,
                  code: r.data.records[coa].coa.Code,
                  name: r.data.records[coa].coa.Name,
                };
              }
            }
            this.dataLineCnSupplier[i].selectCoa.loading = false;
          }
        });
      }
    },
    handlerSearchCoa(s, i) {
      if (s != "") {
        this.dataLineCnSupplier[i].selectCoa.loading = true;
        this.dataLineCnSupplier[i].selectCoa.search = s;
        let coa = this.getOptionCoa(s);
        coa.then((r) => {
          if (this.dataLineCnSupplier[i].selectCoa.search == s) {
            this.dataLineCnSupplier[i].selectCoa.option = [];
            for (var coa = 0; coa < r.data.records.length; coa++) {
              this.dataLineCnSupplier[i].selectCoa.option.push({
                id: r.data.records[coa].coa.ID,
                code: r.data.records[coa].coa.Code,
                name: r.data.records[coa].coa.Name,
              });
            }
            this.dataLineCnSupplier[i].selectCoa.loading = false;
          }
        });
      } else {
        this.dataLineCnSupplier[i].selectCoa.option = this.allDataCoas;
      }
    },
    handleSetSelectTerritory(code, i) {
      console.log(i, "setSelect=>code territory", code);
      if (code != "") {
        this.dataLineCnSupplier[i].selectTerritory.loading = true;
        this.dataLineCnSupplier[i].selectTerritory.search = code;
        let territory = this.getOptionTerritory(code);
        territory.then((r) => {
          if (this.dataLineCnSupplier[i].selectTerritory.search == code) {
            for (var terr = 0; terr < r.data.records.length; terr++) {
              if (r.data.records[terr].code == code) {
                this.dataLineCnSupplier[i].selectTerritory.value = r.data.records[terr];
              }
            }
            this.dataLineCnSupplier[i].selectTerritory.loading = false;
          }
        });
      }
    },
    handlerSearchTerritory(s, i) {
      if (s != "") {
        this.dataLineCnSupplier[i].selectTerritory.loading = true;
        this.dataLineCnSupplier[i].selectTerritory.search = s;
        let territory = this.getOptionTerritory(s);
        territory.then((r) => {
          if (this.dataLineCnSupplier[i].selectTerritory.search == s) {
            this.dataLineCnSupplier[i].selectTerritory.option = [];
            for (var terr = 0; terr < r.data.records.length; terr++) {
              this.dataLineCnSupplier[i].selectTerritory.option.push(
                r.data.records[terr]
              );
            }
            this.dataLineCnSupplier[i].selectTerritory.loading = false;
          }
        });
      } else {
        this.dataLineCnSupplier[i].selectTerritory.option = this.allDataTerrytories;
      }
    },
    handleSetSelectCustomer(code, i) {
      console.log(i, "setSelect=>code customer", code);
      if (code != "") {
        this.dataLineCnSupplier[i].selectCustomer.loading = true;
        this.dataLineCnSupplier[i].selectCustomer.search = code;
        let customer = this.getOptionCustomer(code, i);
        customer.then((r) => {
          if (this.dataLineCnSupplier[i].selectCustomer.search == code) {
            for (var cust = 0; cust < r.data.records.length; cust++) {
              if (r.data.records[cust].code == code) {
                this.dataLineCnSupplier[i].selectCustomer.value = r.data.records[cust];
              }
            }
            this.dataLineCnSupplier[i].selectCustomer.loading = false;
          }
        });
      }
    },
    handlerSearchCustomer(s, i) {
      if (s != "") {
        this.dataLineCnSupplier[i].selectCustomer.loading = true;
        this.dataLineCnSupplier[i].selectCustomer.search = s;
        let customer = this.getOptionCustomer(s, i);
        customer.then((r) => {
          if (this.dataLineCnSupplier[i].selectCustomer.search == s) {
            this.dataLineCnSupplier[i].selectCustomer.option = [];
            for (var cust = 0; cust < r.data.records.length; cust++) {
              this.dataLineCnSupplier[i].selectCustomer.option.push(r.data.records[cust]);
            }
            this.dataLineCnSupplier[i].selectCustomer.loading = false;
          }
        });
      } else {
        this.dataLineCnSupplier[i].selectCustomer.option = this.allDataCustomers;
      }
    },
    filterCustomerByTerritory(terr_id = 0, i) {
      if (terr_id > 0) {
        this.dataLineCnSupplier[i].selectCustomer.loading = true;
        this.dataLineCnSupplier[i].selectCustomer.territory_id = terr_id;
        this.resetDataOptionCust = false;
        let customer = this.getOptionCustomer("", i);
        customer.then((r) => {
          if (this.dataLineCnSupplier[i].selectCustomer.territory_id == terr_id) {
            this.dataLineCnSupplier[i].selectCustomer.option = [];
            for (var cust = 0; cust < r.data.records.length; cust++) {
              this.dataLineCnSupplier[i].selectCustomer.option.push(r.data.records[cust]);
            }
            this.dataLineCnSupplier[i].selectCustomer.loading = false;
          }
        });
      } else {
        this.dataLineCnSupplier[i].selectCustomer.option = this.allDataCustomers;
      }
    },
    handleSetSelectCostCenter(name, i) {
      console.log(i, "setSelect=>name cost canter", name);
      if (name != "") {
        this.dataLineCnSupplier[i].selectCostCenter.loading = true;
        this.dataLineCnSupplier[i].selectCostCenter.search = name;
        let territory = this.getOptionCostCenter(name);
        territory.then((r) => {
          if (this.dataLineCnSupplier[i].selectCostCenter.search == name) {
            for (var cc = 0; cc < r.data.records.length; cc++) {
              if (r.data.records[cc].Name == name) {
                this.dataLineCnSupplier[i].selectCostCenter.value = {
                  id: r.data.records[cc].ID,
                  code: r.data.records[cc].Code,
                  name: r.data.records[cc].Name,
                };
              }
            }
            this.dataLineCnSupplier[i].selectCostCenter.loading = false;
          }
        });
      }
    },
    handlerSearchCostCenter(s, i) {
      if (s != "") {
        this.dataLineCnSupplier[i].selectCostCenter.loading = true;
        this.dataLineCnSupplier[i].selectCostCenter.search = s;
        let cc = this.getOptionCostCenter(s);
        cc.then((r) => {
          if (this.dataLineCnSupplier[i].selectCostCenter.search == s) {
            this.dataLineCnSupplier[i].selectCostCenter.option = [];
            for (var cc = 0; cc < r.data.records.length; cc++) {
              this.dataLineCnSupplier[i].selectCostCenter.option.push({
                id: r.data.records[cc].ID,
                code: r.data.records[cc].Code,
                name: r.data.records[cc].Name,
              });
            }
            this.dataLineCnSupplier[i].selectCostCenter.loading = false;
          }
        });
      } else {
        this.dataLineCnSupplier[i].selectCostCenter.option = this.allDataCostCenters;
      }
    },
  },
};
</script>
<style>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
}
.whitespace span {
  background: transparent !important;
}
.vs-input-right .vs-inputx {
  text-align: right !important;
}
.con-vs-popup .vs-popup {
  width: 100%;
  min-height: 50%;
}
</style>
